import {
	Hayd,
	Indeterminate,
	Istihadah,
	MostLikelyHayd,
	MostLikelyIstihadah,
	MostLikelyTuhr,
	OngoingHayd,
	Tuhr
} from '@al-mabsut/muslimah';
import cx from '../../lib/cx';

const Test = ({ class: c, clickedItem, close, ...props }) => {
	return (
		<div class={cx('full-width', c)} {...props}>
			<Hayd />
			<Indeterminate scenario="initial-bleeding" />
			<Istihadah />
			<MostLikelyHayd />
			<MostLikelyIstihadah />
			<MostLikelyTuhr scenario="before-habit" />
			<OngoingHayd scenario="until-habit" />
			<Tuhr />
		</div>
	);
};

export default Test;
