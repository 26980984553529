import style from './style';
import Glossary from '../glossary';
import { useState } from 'preact/hooks';

const Glossaryitem = ({ children, term, ...props }) => {
	const [clickedItem, setClickedItem] = useState();

	const handleClick = () => {
		const item = term ? term : children?.length > 0 ? children[0] : null;
		if (item) {
			setClickedItem(item);
		}
	};

	const closePopUp = () => {
		setClickedItem();
	};

	return (
		<span>
			<span class={style.span} {...props} onClick={handleClick}>
				{children}
			</span>
			{clickedItem && (
				<div class={style.popUpContainer} onClick={closePopUp}>
					<div class={style.popUp} onClick={e => e.stopPropagation()}>
						<Glossary clickedItem={clickedItem} close={closePopUp} />
					</div>
				</div>
			)}
		</span>
	);
};

export default Glossaryitem;
