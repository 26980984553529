import style from './style';
import { useState, useEffect } from 'preact/hooks';

const generateUUID = () => {
	let d = new Date().getTime();
	let d2 = (performance && performance.now && performance.now() * 1000) || 0;
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		let r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
	});
};

const Like = ({ feature, ...props }) => {
	const [canAddLike, setCanAddLike] = useState(true);

	useEffect(() => {
		const localUserId = localStorage.getItem('_id');
		if (!localUserId) {
			// this is new user generate new id
			const newId = generateUUID();
			localStorage.setItem('_id', newId);
		} else {
			const localFeatures = localStorage.getItem('features');
			if (localFeatures) {
				const arrayOfFeatures = JSON.parse(localFeatures);
				if (arrayOfFeatures.includes(feature)) {
					setCanAddLike(false);
				}
			}
		}
	}, []);

	const handleSubmit = () => {
		// wait until animation is done
		setTimeout(() => {
			setCanAddLike(false);
		}, 1000);
		// get prev. features if any
		let arrayOfFeatures = [];
		const localFeatures = localStorage.getItem('features');
		if (localFeatures) {
			arrayOfFeatures = JSON.parse(localFeatures);
		}
		arrayOfFeatures.push(feature);
		localStorage.setItem('features', JSON.stringify(arrayOfFeatures));
		// get userId
		const userId = localStorage.getItem('_id');
		// Send data to sheet
		const formData = new FormData();
		formData.append('UserId', userId);
		formData.append('Features', arrayOfFeatures);
		formData.append('Sheet', 'Features');

		fetch(process.env.FETCH_URL, {
			method: 'post',
			body: formData
		}).catch(error => {
			console.log('error ', error);
		});
	};

	if (!canAddLike) {
		return;
	}
	return (
		<section class={style.like} {...props}>
			<button
				class={style.likeButton}
				onClick={handleSubmit}
				aria-label={`like ${feature} feautre`}
			>
				<div class={style.likeWrapper}>
					<div class={style.ripple}></div>
					<svg class={style.heart} width="24" height="24" viewBox="0 0 24 24">
						<path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z"></path>
					</svg>
					<div class={style.particles} style="--total-particles: 6">
						<div class={style.particle} style="--i: 1; --color: #7642F0"></div>
						<div class={style.particle} style="--i: 2; --color: #AFD27F"></div>
						<div class={style.particle} style="--i: 3; --color: #DE8F4F"></div>
						<div class={style.particle} style="--i: 4; --color: #D0516B"></div>
						<div class={style.particle} style="--i: 5; --color: #5686F2"></div>
						<div class={style.particle} style="--i: 6; --color: #D53EF3"></div>
					</div>
				</div>
			</button>
		</section>
	);
};

export default Like;
