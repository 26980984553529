import style from './style.css';
import { useState } from 'preact/hooks';

const Dropdown = ({ title, children, isOpen, ...props }) => {
	const [open, setOpen] = useState(isOpen || false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div class={style.dropdown}>
			<button class={style.item} onClick={handleClick} {...props}>
				<p>{title}</p>
				<div className={style.flex}>
					<span
						className={style.arrow}
						style={{ transform: open ? 'rotate(-90deg)' : 'rotate(90deg)' }}
					/>
				</div>
			</button>

			{open && <div className={style.defaultItem}>{children}</div>}
		</div>
	);
};
export default Dropdown;
