import style from './style';
import FacebookIcon from '../../assets/social/facebook.webp';
import InstagramIcon from '../../assets/social/instagram.webp';
import WhatsappIcon from '../../assets/social/whatsapp.webp';
import TwitterIcon from '../../assets/social/twitter.webp';
import cookies from '../../assets/social/cookies.png';
import { useState, useEffect } from 'preact/hooks';
import CookieConsentPopup from '../CookieConsentPopup';
import cx from '../../lib/cx';

const Social = () => {
	const SCROLLPOINT = 700;
	const [show, setShow] = useState(false);
	const [cookieBannerVisibility, setCookieBannerVisibility] = useState(false);
	/* const deleteCookie = name => {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	}; */
	const handleManageCookies = () => {
		console.log('inside handleManageCookies');
		setCookieBannerVisibility(true);
	};
	const handleShow = () => {
		if (window?.scrollY >= 700) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleShow);

		return () => {
			window.removeEventListener('scroll', handleShow);
		};
	}, []);

	let url =
		'https://www.myhayd.app?utm_medium=wa&utm_source=landing&utm_campaign=landing-1';
	let encodedUrl = encodeURIComponent(url);
	const whatsappUrl = 'https://wa.me/send?text=' + encodedUrl;

	url =
		'https://www.myhayd.app?utm_medium=fb&utm_source=landing&utm_campaign=landing-1';
	encodedUrl = encodeURIComponent(url);
	const facebookUrl =
		'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl;

	url =
		'https://www.myhayd.app?utm_medium=tw&utm_source=landing&utm_campaign=landing-1';
	encodedUrl = encodeURIComponent(url);
	const twitterUrl = 'http://twitter.com/share?url=' + encodedUrl;

	const instagramUrl = 'https://www.instagram.com/myhayd.app';

	return (
		<>
			{cookieBannerVisibility && (
				<CookieConsentPopup
					renderedFromAnotherComponent={cookieBannerVisibility}
					action={() => setCookieBannerVisibility(false)}
				/>
			)}
			<section class={cx(style.socialContainer, show ? style.show : '')}>
				<div class={`${style.social} ${style.facebook}`}>
					<a href={facebookUrl} target="_blank" rel="noreferrer">
						<p>
							<span>Share on Facebook</span>{' '}
							<img src={FacebookIcon} alt="Facebook" />
						</p>
					</a>
				</div>
				<div class={`${style.social} ${style.twitter}`}>
					<a href={twitterUrl} target="_blank" rel="noreferrer">
						<p>
							<span>Share on Twitter</span>{' '}
							<img src={TwitterIcon} alt="Twitter" />
						</p>
					</a>
				</div>
				<div class={`${style.social} ${style.instagram}`}>
					<a href={instagramUrl} target="_blank" rel="noreferrer">
						<p>
							<span>Follow on Instagram</span>{' '}
							<img src={InstagramIcon} alt="Instagram" />
						</p>
					</a>
				</div>
				<div class={`${style.social} ${style.whatsapp}`}>
					<a href={whatsappUrl} target="_blank" rel="noreferrer">
						<p>
							<span>Share on Whatsapp</span>{' '}
							<img src={WhatsappIcon} alt="Whatsapp" />
						</p>
					</a>
				</div>
				<div className={`${style.social} ${style.cookies}`}>
					<a href="#" onClick={handleManageCookies}>
						<p>
							<span>Manage Cookies</span>{' '}
							<img src={cookies} alt="Manage Cookies" />
						</p>
					</a>
				</div>
			</section>
		</>
	);
};

export default Social;
