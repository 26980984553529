import style from './style.css';

const Btn = ({ text, otherStyle, action, disabled, id }) => {
	return (
		<button
			title={text}
			className={`${style.btn} ${otherStyle ? otherStyle : ''}`}
			id={id ? id : ''}
			onClick={action}
			disabled={disabled}
		>
			{text}
		</button>
	);
};

export default Btn;
