import style from './style.css';

const CheckboxSwitch = ({ action, active, otherStyle }) => {
	return (
		<button
			className={`${style.checkbox} ${otherStyle ? otherStyle : ''} ${
				active ? style.active : ''
			}`}
			onClick={action}
			title="checkBox"
		>
			<span
				className={`${
					console.dir() && console.dir() === 'rtl'
						? style.checkHandle
						: style.checkHandleRTL
				}
         ${
						active
							? console.dir() && console.dir() === 'rtl'
								? style.handleActive
								: style.handleActiveRTL
							: ''
					}`}
			/>
		</button>
	);
};

export default CheckboxSwitch;
