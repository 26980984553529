import { useState, useEffect } from 'preact/hooks';

const GetAppBtn = () => {
	const [params, setParams] = useState();

	useEffect(() => {
		const referrer = document.referrer.toLowerCase();

		if (!referrer) {
			// mostly direct
			setParams(`utm_source=landing&utm_medium=direct`);
			return;
		}

		let source;

		if (referrer.includes('pinterest')) {
			source = 'pinterest';
		} else if (referrer.includes('facebook') || referrer.includes('fb')) {
			source = 'facebook';
		} else if (referrer.includes('twitter') || referrer.includes('t.co')) {
			source = 'twitter';
		} else if (referrer.includes('instagram') || referrer.includes('ig')) {
			source = 'instagram';
		} else if (referrer.includes('linkedin') || referrer.includes('lnkd')) {
			source = 'linkedin';
		} else if (referrer.includes('whatsapp')) {
			source = 'whatsapp';
		} else if (referrer.includes('snapchat')) {
			source = 'snapchat';
		} else if (referrer.includes('tiktok')) {
			source = 'tiktok';
		} else if (referrer.includes('youtube')) {
			source = 'youtube';
		}

		if (source) {
			setParams(`utm_source=${source}&utm_medium=social`);
		} else {
			// unknown
			setParams(`utm_source=${referrer}&utm_medium=unknown`);
		}
	}, []);

	return (
		<a
			className="btn primary"
			onClick={e => {
				e.preventDefault();

				let analyticalCookies = localStorage.getItem('analyticalCookies');
				if (analyticalCookies) {
					analyticalCookies = JSON.parse(analyticalCookies);
				}

				const url = `https://myhayd.app/en/onboarding?${params}${
					analyticalCookies ? '&cookies=true' : ''
				}`;
				const newWindow = window.open(url, '_blank');

				// fallback
				if (
					!newWindow ||
					newWindow?.closed ||
					typeof newWindow?.closed === 'undefined'
				) {
					window.location.href = url;
				}
			}}
		>
			Get The App
		</a>
	);
};

export default GetAppBtn;
