import style from './style.css';
import { useState, useEffect } from 'react';
import Logo from '../../assets/mstile-70x70.png';
import CheckboxSwitch from '../checkbox_switch';
import Btn from '../btn';
import Plausible from 'plausible-tracker';

const plausible = Plausible({
	domain: location?.host || 'www.myhayd.app'
});

const NecessaryPopUp = ({ action }) => {
	return (
		<button
			className={`${style.modalContainer} ${style.necessaryPopUp}`}
			onClick={action}
		>
			<div className={style.modalBox}>
				<img alt="logo" src={Logo} />
				<ul>
					<li>
						To ensure compliance with GDPR regulations, and to maintain the
						functionality of our app, we require to inform you of our use of
						necessary cookies.
					</li>
					<li>
						These cookies are essential for the smooth operation of our app and
						cannot be disabled.
					</li>
					<li>
						Please note that these cookies do not track or monitor your
						activities; their sole purpose is to enable you to use our app
						effectively.
					</li>
					<li>
						We respect your privacy and assure you that we do not engage in any
						form of intrusive behavior.
					</li>
				</ul>
				{action && (
					<Btn text={'Okay'} action={action} otherStyle={style.okayBtn} />
				)}
			</div>
		</button>
	);
};

const CookieConsentPopup = ({
	action,
	component,
	otherStyle,
	errorCode,
	translationDetails,
	fixedStyle,
	renderedFromAnotherComponent = false
}) => {
	const [buttonDisplay, setButtonDisplay] = useState(true);
	const [manageDisplay, setManageDisplay] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [necessaryModal, setNecessaryModal] = useState(false);
	const [hideModal, setHideModal] = useState(true);
	const [cookiePreferences, setCookiePreferences] = useState([
		{
			label: 'Necessary Cookies',
			state: true,
			active: false
		},
		{
			label: 'Analytical Cookies',
			state:
				typeof window !== 'undefined' &&
				JSON.parse(localStorage.getItem('analyticalCookies'))
					? JSON.parse(localStorage.getItem('analyticalCookies'))
					: true,
			active: false
		}
	]);

	const initAnalyticalTools = () => {
		localStorage.removeItem('plausible_ignore');
		plausible.enableAutoPageviews();
		plausible.trackEvent('Type', { props: { type: 'www.myhayd.app' } });
	};

	const unInitAnalyticalTools = () => {
		localStorage.setItem('plausible_ignore', JSON.stringify(true));
		location.reload();
	};

	useEffect(() => {
		const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
		if (
			(cookieConsent && !renderedFromAnotherComponent) ||
			location.pathname.includes('privacy-policy')
		) {
			setHideModal(true);
		} else {
			setHideModal(false);
		}
	}, []);

	useEffect(() => {
		// Just as extra check
		const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
		const analyticalCookies = JSON.parse(
			localStorage.getItem('analyticalCookies')
		);
		if (cookieConsent && analyticalCookies) {
			// the user accepted the cookies
			initAnalyticalTools();
		} else if (cookieConsent) {
			localStorage.setItem('plausible_ignore', JSON.stringify(true));
		}
	}, [isChanged]);

	const actionAccept = () => {
		if (action) {
			action();
		}
		setHideModal(true);
		setButtonDisplay(false);
		setManageDisplay(false);
		localStorage.setItem('cookieConsent', JSON.stringify(true));
		localStorage.setItem('analyticalCookies', JSON.stringify(true));
		setCookiePreferences([
			{
				label: 'Necessary Cookies',
				state: true,
				active: false
			},
			{
				label: 'Analytical Cookies',
				state: true,
				active: false
			}
		]);
		initAnalyticalTools();
	};
	const actionManage = () => {
		setButtonDisplay(false);
		setManageDisplay(true);
		setHideModal(true);
	};
	const handleAccess = index => {
		if (index == 0) {
			setNecessaryModal(true);
			return;
		}
		setIsChanged(true);
		setCookiePreferences(e => {
			e[index].state = !cookiePreferences[index].state;
			return [...e];
		});
	};
	const handleSave = () => {
		setIsChanged(false);
		setManageDisplay(false);
		setHideModal(true);
		if (action) {
			action();
		}
		localStorage.setItem('cookieConsent', JSON.stringify(true));
		localStorage.setItem(
			'analyticalCookies',
			JSON.stringify(cookiePreferences[1].state)
		);
		if (!cookiePreferences[1].state) {
			unInitAnalyticalTools();
		} else {
			actionAccept();
		}
	};
	return (
		<>
			{necessaryModal && (
				<NecessaryPopUp action={() => setNecessaryModal(false)} />
			)}
			{(renderedFromAnotherComponent || !hideModal) && (
				<div
					className={`${style.modalContainer} ${otherStyle || ''} ${
						fixedStyle ? style.fixed : ''
					}`}
				>
					<div className={style.modalBox}>
						<img alt="logo" src={Logo} />
						<ul>
							<li>
								We are committed to protecting your privacy and enhancing your
								experience with our app for the purpose of which we use cookies.
							</li>
							<li>
								These small data files help us understand how users interact
								with our app, allowing us to improve its functionality and
								ensure our privacy practices meet your needs.
							</li>
							<li>
								We promise that this data is purely used to enhance your user
								experience and they are not about invading your privacy
							</li>
							<li>
								By accepting these cookies, you're playing a significant part in
								our ongoing development efforts.
							</li>
							<li>
								You can change your preferences anytime. Thank you for your
								understanding and support.
							</li>
							<li>
								Our{' '}
								<a
									href={`${location.origin}/about/privacy-policy`}
									target="_blank"
									className={style.link}
								>
									privacy policy
								</a>
							</li>
						</ul>
						{component && component}
						{buttonDisplay && (
							<div className={style.btnContainer}>
								<Btn
									text="Accept"
									action={actionAccept}
									otherStyle={style.okayBtn}
								/>
								<Btn
									text="Manage"
									action={actionManage}
									otherStyle={style.okayBtn}
								/>
							</div>
						)}
					</div>
				</div>
			)}
			{manageDisplay && (
				<div
					className={`${style.modalContainer} ${otherStyle || ''} ${
						fixedStyle ? style.fixed : ''
					}`}
				>
					<div className={style.modalBox}>
						<img alt="logo" src={Logo} />
						<h2>Customize cookies</h2>
						{component && component}
						<div className={style.cookies}>
							{cookiePreferences &&
								cookiePreferences.map((item, index) => (
									<div key={item.label} className={style.item}>
										<p className={style.title}>{item.label}</p>
										<CheckboxSwitch
											action={() => handleAccess(index)}
											active={item.state}
										/>
									</div>
								))}

							<div className={style.btnContainer}>
								<Btn
									text="Save Changes"
									action={() => handleSave()}
									otherStyle={style.okayBtn}
									disabled={!isChanged}
								/>
								<Btn text="Accept All" action={actionAccept} />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CookieConsentPopup;
