import Jumbotron from './jumbotron';
// import GithubStars from './github-stars';
import TodoList from './todo-list';
// import Logo from './logo';
import Toc from './table-of-contents';
// import Sponsors from './sponsors';
// import WeAreUsing from './we-are-using';
import Email from './email';
import Dropdown from './drop-down';
import Like from './like';
import Glossary from './glossary';
import Glossaryitem from './glossaryitem';
import Test from './test-component';
import GetAppBtn from './getappbtn';

export default {
	Toc,
	Jumbotron,
	// GithubStars,
	TodoList,
	// Logo,
	Email,
	Dropdown,
	Like,
	Glossary,
	Glossaryitem,
	Getappbtn: GetAppBtn,
	Test
};
