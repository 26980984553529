import { h, Component } from 'preact';
import cx from '../../lib/cx';
// import { InvertedLogo } from '../logo';
// import Search from './search';
import style from './style';
import { useStore } from '../store-adapter';
import config from '../../config';
import { useCallback, useEffect } from 'preact/hooks';
// import Corner from './corner';
import { useOverlayToggle } from '../../lib/toggle-overlay';
import Logo from '../../assets/home/logo.webp';

export default function Header() {
	const { url } = useStore(['url']).state;
	const [open, setOpen] = useOverlayToggle(false);
	const toggle = useCallback(() => setOpen(!open), [open]);

	useEffect(() => {
		if (open) setOpen(false);
	}, [url]);

	return (
		<header class={cx(style.header, open && style.open)}>
			<div class={style.inner}>
				<a href={'/'} class={style.logo}>
					<img alt="logo" src={Logo} width="40" height="40" />
					<h1>MyHayd App</h1>
				</a>
				<Nav class={style.nav} routes={config.nav} current={url} />
				{/* <Search /> */}
				<div class={style.social}>
					{/* <a
						class={style.socialItem}
						aria-label="Browse the code on GitHub"
						href="https://github.com/preactjs/preact"
					>
						<img src="/assets/github.svg" alt="GitHub" width="34" height="33" />
					</a>
					<a
						class={style.socialItem}
						aria-label="Follow us on Twitter"
						href="https://twitter.com/MyHaydApp"
					>
						<img src="/assets/twitter.svg" alt="Twitter" width="34" height="28" />
					</a> */}
				</div>
				<Hamburger open={open} onClick={toggle} />
				{/* <Corner /> */}
			</div>
		</header>
	);
}

// hamburger menu
const Hamburger = ({ open, ...props }) => (
	<div class={style.hamburger} open={open} {...props}>
		<div class={style.hb1} />
		<div class={style.hb2} />
		<div class={style.hb3} />
	</div>
);

// nested nav renderer
const Nav = ({ routes, current, ...props }) => (
	<nav {...props}>
		{routes.map(route => (
			<NavItem
				to={route}
				current={current}
				data-route={getRouteIdent(route)}
				class={cx(
					route.class,
					(route.path === current ||
						(route.content === 'guide' && /^\/guide\//.test(current))) &&
						style.current
				)}
			/>
		))}
	</nav>
);

// nav items are really the only complex bit for menuing, since they handle click events.
class NavItem extends Component {
	state = { open: false };

	close = () => (this.setState({ open: false }), false);

	toggle = () => (this.setState({ open: !this.state.open }), false);

	handleClickOutside = ({ target }) => {
		if (this.state.open) {
			do {
				if (target === this.base) return;
			} while ((target = target.parentNode));
			this.close();
		}
	};

	componentDidMount() {
		addEventListener('click', this.handleClickOutside);
	}

	componentWillUnmount() {
		removeEventListener('click', this.handleClickOutside);
	}

	componentDidUpdate({ current }) {
		if (current !== this.props.current && this.state.open) {
			this.close();
		}
	}

	render({ to, current, ...props }, { open }) {
		if (!to.routes) return <NavLink to={to} {...props} />;

		// prevent displaying test component in navbar
		if (to.content === 'test') {
			return <></>;
		}

		return (
			<div {...props} data-open={open || null} class={style.navGroup}>
				<NavLink to={to} onClick={this.toggle} aria-haspopup isOpen={open} />
				<Nav
					routes={to.routes}
					current={current}
					aria-label="submenu"
					aria-hidden={'' + !open}
				/>
			</div>
		);
	}
}

// depending on the type of nav link, use <a>
const NavLink = ({ to, isOpen, route, ...props }) => {
	const { lang } = useStore(['lang']).state;

	if (!to.path) {
		return (
			<button
				{...props}
				aria-haspopup="true"
				aria-expanded={isOpen}
				data-route={route}
			>
				{getRouteName(to, lang)}
			</button>
		);
	}

	return (
		<a href={to.path} {...props} data-route={route}>
			{getRouteName(to, lang)}
		</a>
	);
};

export function getRouteName(route, lang) {
	if (route) {
		if (typeof route.name === 'object') {
			return route.name[lang] || route.name.en;
		}
		return route.name || route.title;
	}
}

// get a CSS-addressable identifier for a given route
const getRouteIdent = route =>
	(getRouteName(route, 'en') || route.url)
		.toLowerCase()
		.replace(/[^a-z0-9]/i, '');
