import style from './style';
import cx from '../../lib/cx';
import { useEffect, useRef, useState } from 'preact/hooks';
import HeaderIcon from '../../assets/glossary/header-book.svg';
import LeftCloud from '../../assets/glossary/left-cloud.svg';
import RightCloud from '../../assets/glossary/right-cloud.svg';
import Dropdown from '../drop-down';
import Ghusl from '../../assets/glossary/ghusl.png';
import Ablution from '../../assets/glossary/ablution.png';
import Dhikr from '../../assets/glossary/dhikr.png';
import Hajj from '../../assets/glossary/hajj.png';
import Duaa from '../../assets/glossary/duaa.png';
import Marriage from '../../assets/glossary/marriage.png';
import Divorce from '../../assets/glossary/divorce.png';
import Medinah from '../../assets/glossary/medina.png';
import Salaah from '../../assets/glossary/salaah.png';
import Ramadan from '../../assets/glossary/ramadan.png';
import Zakaat from '../../assets/glossary/zakaat.png';
import Quran from '../../assets/glossary/quran.png';
import Fasting from '../../assets/glossary/fasting.png';
import Pregnancy from '../../assets/glossary/pregnancy.png';
import Baby from '../../assets/glossary/baby.png';
import Bleeding from '../../assets/glossary/bleeding.png';
import Hayd from '../../assets/glossary/hayd.png';
import Period from '../../assets/glossary/period.png';
import Tayammum from '../../assets/glossary/tayammum.png';

const glossaries = [
	{
		headTitle: 'Purity',
		arHeadTitle: 'الطَهَارَة'
	},
	{
		title: 'Nifaas',
		arTitle: 'النِّفَاس',
		icon: Period,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Hayd',
		arTitle: 'الْحَيْض',
		icon: Hayd,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Istihaadah',
		arTitle: 'الِاسْتِحَاضَة',
		icon: Bleeding,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Tuhr',
		arTitle: 'الطُّهْر',
		// icon: Tuhr,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Tayammum',
		arTitle: 'التَّيَمُّم',
		icon: Tayammum,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Wudu',
		arTitle: 'الْوُضُوء',
		icon: Ablution,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Ghusl',
		arTitle: 'الغَسْلُ',
		icon: Ghusl,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		headTitle: 'Worship',
		arHeadTitle: 'الْعِبَادَة'
	},
	{
		title: 'Quran',
		arTitle: 'الْقُرْآن',
		icon: Quran,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Dhikr',
		arTitle: 'الذَّكَر',
		icon: Dhikr,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Duaa (Supplication)',
		arTitle: 'الدُّعَاء',
		icon: Duaa,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Salaah (Prayer)',
		arTitle: 'الصَّلَاة',
		icon: Salaah,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Ramadan',
		arTitle: 'رَمَضَان',
		icon: Ramadan,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Fasting',
		arTitle: 'الصَّوْم',
		icon: Fasting,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Zakaat',
		arTitle: 'الزَّكَاة',
		icon: Zakaat,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Hajj',
		arTitle: 'الْحَجّ',
		icon: Hajj,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Umrah',
		arTitle: 'الْعُمْرَة',
		icon: Hajj,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Mecca',
		arTitle: 'مَكَّة',
		icon: Hajj,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Medinah',
		arTitle: 'الْمَدِينَة',
		icon: Medinah,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		headTitle: "Mu'aasharat (Social Relations)",
		arHeadTitle: 'الْمُعَاشَرَة'
	},
	{
		title: 'Marriage',
		arTitle: 'النِكَاح',
		icon: Marriage,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Pregnancy',
		arTitle: 'الْحَمْل',
		icon: Pregnancy,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Birthing',
		arTitle: 'وِلَادَة طِفْل',
		icon: Baby,
		description: 'Description/Explanation coming soon inshallah'
	},
	{
		title: 'Divorce',
		arTitle: 'الطَّلَاق',
		icon: Divorce,
		description: 'Description/Explanation coming soon inshallah'
	}
];

const Glossary = ({ class: c, clickedItem, close, ...props }) => {
	const [displayCloseBtn, setDisplayCloseBtn] = useState(false);
	const clickedItemRef = useRef();

	useEffect(() => {
		if (clickedItem && !displayCloseBtn) {
			setDisplayCloseBtn(true);
		}
	}, []);

	useEffect(() => {
		clickedItemRef?.current?.scrollIntoView();
	}, [clickedItemRef]);

	return (
		<div class={cx(style.glossary, 'full-width', c)} {...props}>
			<div class={style.headerContainer}>
				<div
					class={cx(
						style.glossaryHeader,
						clickedItem ? style.popUpHeader : style.glossaryHeaderFixWidth
					)}
				>
					{displayCloseBtn && <button class={style.closeBtn} onClick={close} />}
					<h1 class={style.glossaryTitle}>Glossary</h1>
					<p class={style.glossarySlogan}>
						Find here all the Islamic terms and terminologies
					</p>
					<img class={style.glossaryHeaderIcon} src={HeaderIcon} alt="" />
					<img class={style.glossaryLeftCloud} src={LeftCloud} alt="" />
					<img class={style.glossaryRightCloud} src={RightCloud} alt="" />
				</div>
			</div>
			<div class={style.glossaries}>
				{glossaries.map(glossaryItem => (
					<div
						class={style.glossaryItem}
						ref={
							clickedItem &&
							glossaryItem?.title &&
							clickedItem.toLowerCase() ==
								glossaryItem.title.split(' ')[0].toLowerCase()
								? clickedItemRef
								: null
						}
					>
						{glossaryItem?.title ? (
							<Dropdown
								title={
									<div class={style.glossaryItemTitle}>
										{glossaryItem?.icon && (
											<img src={glossaryItem.icon} alt={glossaryItem.title} />
										)}
										<h3>
											<span>{glossaryItem.title}</span>
											<span>{glossaryItem.arTitle}</span>
										</h3>
									</div>
								}
								isOpen={
									clickedItem &&
									glossaryItem?.title &&
									clickedItem.toLowerCase() ==
										glossaryItem.title.split(' ')[0].toLowerCase()
								}
							>
								<p class={style.glossaryItemDescription}>
									{glossaryItem.description}
								</p>
							</Dropdown>
						) : (
							<h2>
								<span>{glossaryItem.headTitle}</span>
								<span>{glossaryItem.arHeadTitle}</span>
							</h2>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default Glossary;
