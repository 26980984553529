import style from './style';
import { useEffect, useState } from 'preact/hooks';
import { localStorageGet, localStorageSet } from '../../lib/localstorage';

const Email = ({ children, buttontext, errortext, successtext, ...props }) => {
	const [email, setEmail] = useState();
	const [fakeEmail, setFakeEmail] = useState();
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const EMAILREGEX = /\S+@\S+\.\S+/;
	const handleSubmit = () => {
		if (
			!(email || fakeEmail) ||
			!(EMAILREGEX.test(email) || EMAILREGEX.test(fakeEmail))
		) {
			setError(true);
			return;
		}

		// Clear prev. error message if any
		setError(false);

		if (email) {
			// Send data to sheet
			const formData = new FormData();
			formData.append('Email', email.toLowerCase());
			formData.append('Sheet', 'Emails');
			formData.append('Verified', 'False');

			fetch(process.env.FETCH_URL, {
				method: 'post',
				body: formData
			}).catch(error => {
				console.log('error ', error);
			});
		}

		// Display success message in both cases fake and real emails
		setSuccess(true);
		localStorageSet('signed_up', true);
	};

	useEffect(() => {
		if (localStorageGet('signed_up') == true) {
			setSuccess(true);
		}
	}, []);

	return (
		<section class={style.email} {...props}>
			{children}
			{!success ? (
				<div class={style.inputContainer}>
					<input
						type="text"
						name="name"
						placeholder="your-email@domain.com"
						onChange={e => setEmail(e.target.value)}
						value={email}
					/>
					<input
						class={style.hide}
						type="email"
						name="email"
						placeholder="your-email@domain.com"
						onChange={e => setFakeEmail(e.target.value)}
						value={fakeEmail}
					/>
					{error && <p class={style.error}>{errortext}</p>}
					<button class={style.emailBtn} onClick={handleSubmit}>
						{buttontext}
					</button>
				</div>
			) : (
				<p>{successtext}</p>
			)}
		</section>
	);
};

export default Email;
